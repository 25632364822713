/* PlantInfoPage.css */

/* Customize the styles for the progress bar container */
.progress-bar-container {
  position: relative;
}

/* Customize the styles for the progress bar */
.progress-bar {
  width: 100%;
}

/* Customize the styles for tick marks */
.tick-mark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px; /* Adjust the font size as needed */
  color: #000; /* Tick mark color */
}

/* Adjust the position of tick marks based on the percentage */
.tick-0 {
  left: 0;
}

.tick-25 {
  left: 25%;
}

.tick-50 {
  left: 50%;
}

.tick-75 {
  left: 75%;
}

.tick-100 {
  right: 0;
}
